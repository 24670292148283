<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        ref="addNewDebrisTypeForm"
        id="addNewDebrisTypeForm"
        class="custom-form pt-6"
        @submit.prevent="submitNewDebrisTypeForm"
      >
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-text-field
            v-model="debrisType.displayName"
            outlined
            :label="'Typ ' + debrisGenitive"
            :rules="[rules.required]"
            :placeholder="'Wpisz nazwę ' +debrisGenitive"
          />
        </v-col>
        <v-col
          cols="12"
          class="pt-0"
        >
          <v-checkbox
            v-model="debrisType.visibleForDriver"
            label="Widoczne dla kierowcy"
            hide-details
            dense
            small
            class="mt-0 pt-0"
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        form="addNewDebrisTypeForm"
        class="base-hover"
        :loading="isProcessing"
      >
        Dodaj typ {{ debrisGenitive }}
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'

export default {
  components: {
    DialogFormWrapper,
  },
  mixins: [afterFormSubmitted],
  data () {
    return {
      rules,
      debrisType: {
        displayName: null,
        visibleForDriver: true
      },
    }
  },
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog,
      isProcessing: state => state.debrisTypes.isProcessing || state.aggregateTypes.isProcessing
    }),
    isAggregate () {
      return this.dialog.data.aggregate
    },
    debrisGenitive () {
      return this.isAggregate ? 'kruszywa' : 'odpadu'
    }
  },
  methods: {
    ...mapActions({
      addNewDebrisType: 'debrisTypes/addNewItem',
      getDebrisTypes: 'debrisTypes/getItems',
      getAggregateTypes: 'aggregateTypes/getItems'
    }),
    submitNewDebrisTypeForm () {
      if (this.$refs.addNewDebrisTypeForm.validate()) {
        this.debrisType.aggregate = this.isAggregate
        this.debrisType.name = this.debrisType.displayName // for BE purposes
        // There are seperate modules for aggregate and debris types,
        // but there is only one endpoint with aggregate: boolean attribute
        const refreshTable = this.isAggregate ? this.getAggregateTypes : this.getDebrisTypes

        this.addNewDebrisType({
          params: this.debrisType,
          endpoint: 'debrisTypes'
        }).then(() => {
          refreshTable()
          this.afterFormSubmitted(`Utworzono nowy typ ${this.debrisGenitive}`)
        })
      }
    }
  }
}
</script>
